import humps from 'humps'
import { createFormDataDocuments, notEmptyPayload } from '_utils/helpers'

import { get, patch, post, del } from './requests'
import { getToken } from '_/utils/token'

export const getProviders = key => params =>
  get(['companyboard'], {
    key,
    params: humps.decamelizeKeys(params),
    transformPayload: true,
  })

export const getProvider = key => providerId =>
  get(['company', providerId], { key, transformPayload: true })

export const getLeadProviders = key => params =>
  get(['lead-providers'], {
    key,
    transformPayload: true,
    params: humps.decamelizeKeys(notEmptyPayload(params)),
  })

export const importLeadProviders = key => payload =>
  post(
    ['lead-providers', 'import-companies'],
    {
      key,
      transformPayload: true,
    },
    payload
  )

export const updateProvider = key => (providerId, payload) => {
  const newPayload = { ...payload }
  if (typeof newPayload?.brandLogoPngUrl === 'string') {
    delete newPayload.brandLogoPngUrl
  }
  return patch(
    ['company', providerId],
    {
      key,
      transformPayload: false,
      formData: true,
    },
    createFormDataDocuments(newPayload)
  )
}
export const createProvider = key => payload =>
  post(
    ['company'],
    {
      key,
      transformPayload: false,
      formData: true,
    },
    createFormDataDocuments(payload)
  )

export const createProviderContract = key => (providerId, payload) =>
  post(
    ['company', providerId, 'create-contract'],
    { key, transformPayload: false, formData: true },
    createFormDataDocuments(payload)
  )

export const createCompany = payload => {
  const headers = getToken()

  return post(['company'], { ...headers }, payload)
}

export const updateCompanyById = (providerId, payload) => {
  const headers = getToken()

  return patch(['company', providerId], { ...headers }, payload)
}

export const deleteProviderAttachment = key => (providerId, attachmentId) =>
  patch(['company', providerId, 'delete-attachment'], { key }, { attachmentId })

export const deleteProviderContract = key => (providerId, attachmentId) =>
  patch(['company', providerId, 'delete-contract'], { key }, { attachmentId })

export const addProviderStaffUser = key => (providerId, payload) =>
  post(['company', providerId, 'staff-users'], { key, transformPayload: true }, payload)

export const deleteProviderStaffUser = key => (providerId, staffId) =>
  del(['company', providerId, 'staff-users', staffId], { key, transformPayload: true })

export const updateProviderStaffUser = key => (providerId, staffId, payload) =>
  patch(['company', providerId, 'staff-users', staffId], { key, transformPayload: true }, payload)

export const getConnetionMessageTool = key => params =>
  get(['connections-message-tool'], {
    key,
    params: humps.decamelizeKeys(params),
    transformPayload: true,
  })

export const getProviderSuggestionsList = key => payload =>
  post(
    ['companyboard/assisted-distribution-providers'],
    {
      key,
      transformPayload: true,
    },
    payload
  )

export const getProviderOrders = key => params =>
  post(
    ['company/get-provider-orders'],
    {
      key,
      transformPayload: true,
    },
    params
  )

export const validateProviderCpfCnpj = key => params =>
  get(['company', 'validate-cpf-cnpj'], {
    key,
    params: humps.decamelizeKeys(params),
    transformPayload: true,
  })

export const getServiceProviderOptions = () => {
  const headers = getToken()

  return get(['serviceprovider/get-options'], {
    ...headers,
  })
}
